var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-content-center align-items-md-center"},[_c('div',{staticClass:"col-md-10"},[_c('ul',{staticClass:"nav nav-pills col-md-12",attrs:{"role":"tablist"}},_vm._l((_vm.menus),function(item,index){return _c('li',{key:index,staticClass:"col-md-3 col-6 col-sm-3 col-lg-3"},[_c('a',{directives:[{name:"show",rawName:"v-show",value:(
            item.nome == 'Permissões'
              ? _vm.lista_permissoes_filial['c_Grupo']
                ? true
                : false
              : true
          ),expression:"\n            item.nome == 'Permissões'\n              ? lista_permissoes_filial['c_Grupo']\n                ? true\n                : false\n              : true\n          "}],staticClass:"card nav-link rounded flex-column align-items-between",attrs:{"data-toggle":"pill"},on:{"click":function($event){return _vm.subpatas(item.route, item)}}},[_c('span',{staticClass:"nav-icon py-2 w-auto"},[_c('span',{staticClass:"svg-icon svg-icon-3x"},[_c('inline-svg',{attrs:{"src":item.url}})],1)]),_c('span',{staticClass:"nav-text font-size-sm font-weight-bolder text-center"},[_vm._v(" "+_vm._s(item.nome)+" ")])])])}),0)]),_c('div',{staticClass:"card col-md-12"},[_vm._v("Rotas : "+_vm._s(_vm.routers))]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"full-height bg-ligth row"},[_c('div',{staticClass:"col-sm-12 col-md-5 col-lg-4 col-xs-4 col-12"},[_c('Diretorio',{on:{"clickPasta":_vm.itemClick}})],1),_c('div',{staticClass:"col-sm-12 col-md-4 col-lg-4 col-xs-4 col-12"},[_c('h1',[_vm._v("Meus arquivos")]),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"loading":_vm.loading,"elevation":hover ? 24 : 6}},[_c('template',{slot:"progress"},[_c('v-overlay',{staticClass:"d-flex flex-column text-center",attrs:{"absolute":""}},[_c('div',[_c('v-progress-circular',{attrs:{"size":"75","indeterminate":""}},[_c('span',[_vm._v("Loading")])])],1),_c('div')])],1),_c('b-table',{staticClass:"table table-vertical-center table-borderless",attrs:{"hover":"","Small":"","fields":['nome', 'acoes'],"items":_vm.lista_pastas_doc,"per-page":_vm.perPage,"current-page":_vm.currentPage,"id":"empresa-table","show-empty":"","empty-text":"Nenhum registro encontrado!"},scopedSlots:_vm._u([{key:"head(acoes)",fn:function(){return [_c('span')]},proxy:true},{key:"cell(nome)",fn:function(ref){
          var item = ref.item;
return [_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover",value:('This is a prod'),expression:"'This is a prod'",modifiers:{"hover":true}}]},[_c('b-icon',{attrs:{"icon":item.icon,"aria-hidden":"true"}}),_vm._v(" "+_vm._s(item.nome)+" ")],1)]}},{key:"cell(acoes)",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-right w-100"},[(item.apenasVer)?_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.lista_permissoes_filial.u_Pastas),expression:"lista_permissoes_filial.u_Pastas"},{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-icon btn-light btn-sm mx-1",attrs:{"title":"Editar registro"},on:{"click":function($event){return _vm.atualizar(item)}}},[_c('i',{staticClass:"far fa-edit text-primary"})]):_vm._e(),(item.apenasVer)?_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.lista_permissoes_filial.lock_Pastas),expression:"lista_permissoes_filial.lock_Pastas"},{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-icon btn-light btn-sm mx-1",attrs:{"title":"Bloquear/Desbloquear registro"},on:{"click":function($event){return _vm.confirm(
                          item.icon == 'folder' ? 'Pasta' : 'Arquivo',
                          item
                        )}}},[_c('i',{staticClass:"fas fa-unlock-alt text-danger"})]):_vm._e(),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.lista_permissoes_filial.ra_Pastas),expression:"lista_permissoes_filial.ra_Pastas"},{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-icon btn-light btn-sm mx-1",attrs:{"title":"Entrar"},on:{"click":function($event){return _vm.itemSubsClick(
                          item,
                          item.icon == 'folder' ? 'folder' : 'file'
                        )}}},[_c('b-icon',{attrs:{"icon":"arrow-up-square","scale":"2","variant":"success"}})],1)])]}}],null,true)}),_c('div',{staticClass:"row"},[_c('b-pagination',{staticClass:"col-md-12",attrs:{"pills":"","total-rows":_vm.lista_pastas_doc.length,"per-page":_vm.perPage,"aria-controls":"empresa-table","first-text":"⏮","prev-text":"⏪","next-text":"⏩","last-text":"⏭"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('i',{staticClass:"ml-3"},[_vm._v(" sub-pastas:"+_vm._s(_vm.lista_itens[0].folder))]),_c('i',{staticClass:"ml-1"},[_vm._v(" | ")]),_c('i',{staticClass:"ml-1"},[_vm._v(" arquivos:"+_vm._s(_vm.lista_itens[1].arquivos))])],1)],2)]}}])})],1),_c('div',{staticClass:"col-sm-12 col-md-3 col-lg-4 col-xs-4 col-12"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("Uploads fresh")]),_c('Upload',{attrs:{"dialog":_vm.uploadDialog,"multiple":true},on:{"update:dialog":function($event){_vm.uploadDialog=$event},"filesUploaded":function($event){return _vm.processUpload($event)}}}),_c('div',{staticClass:"row justify-content-end"},[_c('div',{staticClass:"col-sm-5 col-md-6 col-lg-3 col-xs-3 col-3"},[_c('v-checkbox',{attrs:{"label":"OCR","color":"success","hide-details":""},model:{value:(_vm.check_ocr),callback:function ($$v) {_vm.check_ocr=$$v},expression:"check_ocr"}})],1)])],1),_c('v-col',{attrs:{"cols":"12 row"}},_vm._l((_vm.lista_arquivos_upload_progress),function(item,index){return _c('div',{key:index,staticClass:"col-md-12 col-12"},[_c('v-snackbar',{staticClass:"mt-5",staticStyle:{"font-size":"15px"},attrs:{"timeout":2000,"value":item.progress === 100,"right":"","bottom":"","color":"success"}},[_vm._v(" Upload concluído com sucesso!` ")]),_c('v-progress-linear',{attrs:{"value":item.progress,"color":"green","height":"20","indeterminate":item.progress === 0 || item.progress === 99},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var value = ref.value;
return [_c('div',{staticClass:"progress-text"},[_vm._v(" "+_vm._s(item.nome.length > 30 ? item.nome.slice(0, 30) + "..." : item.nome)+" ")]),_c('div',{staticClass:"progress-text ml-1"},[_vm._v(_vm._s(value)+"%")])]}}],null,true)})],1)}),0)],1)],1)])]),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.modal_move_folder_file),callback:function ($$v) {_vm.modal_move_folder_file=$$v},expression:"modal_move_folder_file"}},[[_c('v-card',[_c('v-card-title',[_vm._v("Mover")]),_c('v-divider'),_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-md-12"},[_c('h1',[_vm._v("Diretorios")]),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var hover = ref.hover;
return [_c('v-card',{staticClass:"pt-2",attrs:{"elevation":hover ? 24 : 6}},[_c('v-jstree',{staticStyle:{"overflow-y":"auto","min-height":"100px"},attrs:{"data":_vm.lista_pastas,"multiple":"","allow-batch":"","whole-row":""},on:{"item-click":_vm.itemClick2}})],1)]}}])},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('label',[_vm._v(" Pasta Escolhida: "),_c('b-icon',{attrs:{"icon":"folder","aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.id_pastas_mover.text))],1)]),_c('div',{staticClass:"col-md-6 justify-content-end"},[_c('v-btn',{attrs:{"elevation":"2","color":"primary"},on:{"click":function($event){return _vm.mover()}}},[_vm._v(" Mover")])],1)])])],1)])],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }