<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-10">
      <ul class="nav nav-pills col-md-12" role="tablist">
        <li
          v-for="(item, index) in menus"
          :key="index"
          class="col-md-3 col-6 col-sm-3 col-lg-3"
        >
          <a
            v-show="
              item.nome == 'Permissões'
                ? lista_permissoes_filial['c_Grupo']
                  ? true
                  : false
                : true
            "
            class="card nav-link rounded flex-column align-items-between"
            data-toggle="pill"
            style=""
            @click="subpatas(item.route, item)"
          >
            <span class="nav-icon py-2 w-auto">
              <span class="svg-icon svg-icon-3x">
                <inline-svg :src="item.url" />
              </span>
            </span>
            <span class="nav-text font-size-sm font-weight-bolder text-center">
              {{ item.nome }}
            </span>
          </a>
        </li>
      </ul>
    </div>
    <div class="card col-md-12">Rotas : {{ routers }}</div>
    <div class="col-md-12">
      <div class="full-height bg-ligth row">
        <div class="col-sm-12 col-md-5 col-lg-4 col-xs-4 col-12">
          <Diretorio @clickPasta="itemClick" />
        </div>
        <div class="col-sm-12 col-md-4 col-lg-4 col-xs-4 col-12">
          <h1>Meus arquivos</h1>
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card
                :loading="loading"
                :elevation="hover ? 24 : 6"
                class="mx-auto"
              >
                <template slot="progress">
                  <v-overlay absolute class="d-flex flex-column text-center">
                    <div>
                      <v-progress-circular size="75" indeterminate>
                        <span>Loading</span>
                      </v-progress-circular>
                    </div>
                    <div></div>
                  </v-overlay>
                </template>
                <b-table
                  hover
                  Small
                  :fields="['nome', 'acoes']"
                  :items="lista_pastas_doc"
                  :per-page="perPage"
                  :current-page="currentPage"
                  id="empresa-table"
                  class="table table-vertical-center table-borderless"
                  show-empty
                  empty-text="Nenhum registro encontrado!"
                >
                  <template #head(acoes)><span></span></template>
                  <template #cell(nome)="{ item }">
                    <span v-b-popover.hover="'This is a prod'">
                      <b-icon :icon="item.icon" aria-hidden="true"></b-icon>

                      {{ item.nome }}
                    </span>
                  </template>

                  <template #cell(acoes)="{ item }">
                    <div class="text-right w-100">
                      <button
                        v-if="item.apenasVer"
                        v-show="lista_permissoes_filial.u_Pastas"
                        @click="atualizar(item)"
                        class="btn btn-icon btn-light btn-sm mx-1"
                        v-b-tooltip.hover
                        title="Editar registro"
                      >
                        <i class="far fa-edit text-primary"></i>
                      </button>

                      <button
                        v-if="item.apenasVer"
                        v-show="lista_permissoes_filial.lock_Pastas"
                        @click="
                          confirm(
                            item.icon == 'folder' ? 'Pasta' : 'Arquivo',
                            item
                          )
                        "
                        class="btn btn-icon btn-light btn-sm mx-1"
                        v-b-tooltip.hover
                        title="Bloquear/Desbloquear registro"
                      >
                        <i class="fas fa-unlock-alt text-danger"></i>
                      </button>
                      <button
                        v-show="lista_permissoes_filial.ra_Pastas"
                        @click="
                          itemSubsClick(
                            item,
                            item.icon == 'folder' ? 'folder' : 'file'
                          )
                        "
                        class="btn btn-icon btn-light btn-sm mx-1"
                        v-b-tooltip.hover
                        title="Entrar"
                      >
                        <b-icon
                          icon="arrow-up-square"
                          scale="2"
                          variant="success"
                        ></b-icon>
                      </button>
                    </div>
                  </template>
                </b-table>
                <div class="row">
                  <b-pagination
                    pills
                    class="col-md-12"
                    v-model="currentPage"
                    :total-rows="lista_pastas_doc.length"
                    :per-page="perPage"
                    aria-controls="empresa-table"
                    first-text="⏮"
                    prev-text="⏪"
                    next-text="⏩"
                    last-text="⏭"
                  >
                  </b-pagination>

                  <i class="ml-3"> sub-pastas:{{ lista_itens[0].folder }}</i>
                  <i class="ml-1"> | </i>
                  <i class="ml-1"> arquivos:{{ lista_itens[1].arquivos }}</i>
                </div>
              </v-card></template
            ></v-hover
          >
        </div>
        <div class="col-sm-12 col-md-3 col-lg-4 col-xs-4 col-12">
          <v-row>
            <v-col cols="12">
              <h1>Uploads fresh</h1>
              <Upload
                :dialog.sync="uploadDialog"
                :multiple="true"
                @filesUploaded="processUpload($event)"
              />
              <div class="row justify-content-end">
                <div class="col-sm-5 col-md-6 col-lg-3 col-xs-3 col-3">
                  <v-checkbox
                    v-model="check_ocr"
                    label="OCR"
                    color="success"
                    hide-details
                  ></v-checkbox>
                </div>
              </div>
            </v-col>
            <v-col cols="12 row">
              <div
                class="col-md-12 col-12"
                v-for="(item, index) in lista_arquivos_upload_progress"
                :key="index"
              >
              <v-snackbar
                  class="mt-5"
                  :timeout="2000"
                  :value="item.progress === 100"
                  right
                  bottom
                  color="success"
                  style="font-size: 15px;"
                >
                  Upload concluído com sucesso!`
                 
                </v-snackbar>
                <!-- Mensagem quando o upload for concluído -->
          
                <v-progress-linear
                  :value="item.progress"
                  color="green"
                  height="20"
                  :indeterminate="item.progress === 0 || item.progress === 99"
                >
                  <template v-slot:default="{ value }">
                    <div class="progress-text">
                      {{
                        item.nome.length > 30
                          ? item.nome.slice(0, 30) + "..."
                          : item.nome
                      }}
                    </div>

                    <div class="progress-text ml-1">{{ value }}%</div>
                  </template>
                </v-progress-linear>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>

    <v-dialog v-model="modal_move_folder_file" max-width="600">
      <template>
        <v-card>
          <v-card-title>Mover</v-card-title>
          <v-divider></v-divider>
          <div class="form-group row">
            <div class="col-md-12">
              <h1>Diretorios</h1>
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card :elevation="hover ? 24 : 6" class="pt-2">
                    <v-jstree
                      style="overflow-y: auto; min-height: 100px"
                      :data="lista_pastas"
                      multiple
                      allow-batch
                      whole-row
                      @item-click="itemClick2"
                    ></v-jstree> </v-card
                ></template>
                <div class="row">
                  <div class="col-md-6">
                    <label>
                      Pasta Escolhida:
                      <b-icon icon="folder" aria-hidden="true"></b-icon>
                      {{ id_pastas_mover.text }}</label
                    >
                  </div>
                  <div class="col-md-6 justify-content-end">
                    <v-btn elevation="2" color="primary" @click="mover()">
                      Mover</v-btn
                    >
                  </div>
                </div>
              </v-hover>
            </div>
          </div>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import VJstree from "vue-jstree";
import Upload from "@/view/components/arquivex/upload.vue";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import Diretorio from "@/view/components/arquivex/diretorios";
export default {
  mixins: [fireAlert],
  name: "Layout",
  components: {
    Diretorio,
    VJstree,
    Upload,
  },
  data: function () {
    return {
      check_ocr: true,

      id_pastas_mover: { text: "", id: null },
      modal_move_folder_file: false,
      loading: false,
      uploadDialog: true,
      routers:
        this.$store.state.pastas.lista_pastas_doc.length > 0
          ? this.$store.state.pastas.lista_pastas_doc[0].caminho
          : "",
      subpastas_id:
        this.$store.state.pastas.lista_pastas_doc.length > 0
          ? this.$store.state.pastas.lista_pastas_doc[0].subpastas_id
          : 0,
      currentPage: 1,
      perPage: 10,
      achou: "",
      pasta_select: {},
      counter: 0,
      escolhido: "",
      lista_arquivos_upload_progress: [],
      menus: [
        {
          route: "createPastas",
          nome: "Pastas",
          url: "media/svg/icons/Files/Folder-plus.svg",
        },
        {
          nome: "Ocr Automatico",
          url: "media/svg/icons/Design/Iconocr.svg",
        },
        {
          route: "createDocumento",
          nome: "Documentos",
          url: "media/svg/icons/Files/File-plus.svg",
        },
        {
          permissions: "c_Permission",
          route: "createPermissoes",
          nome: "Permissões",
          url: "media/svg/icons/Home/Library.svg",
        },
      ],
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Arquivex" }]);
  },
  computed: {
    mensagem_alert() {
      return this.$store.state.pastas.mensagem_alert;
    },
    progress() {
      return this.$store.state.pastas.progress;
    },

    at_pasta() {
      return this.$store.state.pastas.at_pasta;
    },

    lista_pastas() {
      return this.$store.state.pastas.lista_pastas;
    },
    lista_pastas2() {
      let folders = Object.values(this.$store.state.pastas.lista_pastas_doc);
      var data = [];

      for (let i = 0; i < folders.length; i++) {
        if (folders[i].icon == "folder") {
          data.push({ id: folders[i].id, label: folders[i].nome });
        }
      }
      return data;
    },
    lista_itens() {
      let folders = Object.values(this.$store.state.pastas.lista_pastas_doc);
      var data = [{ folder: 0 }, { arquivos: 0 }];
      for (let i = 0; i < folders.length; i++) {
        if (folders[i].icon == "folder") {
          data[0].folder = data[0].folder + 1;
        } else {
          data[1].arquivos = data[1].arquivos + 1;
        }
      }
      return data;
    },

    lista_pastas_doc() {
      return this.$store.state.pastas.lista_pastas_doc;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
  },
  watch: {
    // Observando mudanças no progresso
    async progress(newProgress) {
      console.log(newProgress);
      const progressAtual =
        this.lista_arquivos_upload_progress[
          this.lista_arquivos_upload_progress.length - 1
        ].progress;

      for (let index = progressAtual; index <= newProgress; index++) {
        this.lista_arquivos_upload_progress[
          this.lista_arquivos_upload_progress.length - 1
        ].progress = index;
        await new Promise((resolve) => setTimeout(resolve, 5));
      }
    },
  },
  methods: {
    async atualizar(value) {
      switch (value.icon) {
        case "folder":
          this.$store.dispatch("configEmpresa/atualizar", value);
          this.$router.push({
            name: "createPastas",
          });

          break;
        case "file-earmark":
          this.$store.dispatch("configEmpresa/atualizar", value);
          this.$router.push({
            name: "createDocumento",
          });
          break;
      }
    },
    trocar(route) {
      this.$router.push({ name: route });
    },
    subpatas(route, value) {
      if (value.nome == "Mover") {
      } else {
        let a = {
          rota: this.routers,
          pastas_id: this.subpastas_id,
        };
        window.localStorage.setItem("routers", JSON.stringify(a));
        this.$store.dispatch("pastas/set_lista_pastas", this.lista_pastas);
        this.$router.push({
          name: route,
          params: { pastas_id: this.subpastas_id },
        });
      }
    },
    async itemSubsClick(data, tipo) {
      // this.routers =
      switch (tipo) {
        case "folder":
          this.subpastas_id = data.id;
          this.routers = this.routers + "\\" + data.nome;

          this.loading = true;
          await this.$store.dispatch("pastas/listar_pastas_doc", data.id);
          await this.$store.dispatch("pastas/set_pasta", data);

          this.loading = false;
          let a = {
            rota: this.routers,
            pastas_id: this.subpastas_id,
          };
          window.localStorage.setItem("routers", JSON.stringify(a));

          break;
        case "file":
          this.$router.push({
            name: "viewfile",
            params: { arquivo_id: data.id },
          });
          break;
      }
    },
    customItemClickWithCtrl(node) {
      console.log(node);
    },
    customItemClick(node) {
      console.log(node);
    },
    async itemClick(node) {
      this.loading = true;
      this.subpastas_id = node.model.id;
      this.routers = "";
      this.pasta_select = node.model;
      await this.$store.dispatch("pastas/listar_pastas_doc", node.model.id);
      await this.$store.dispatch("pastas/set_pasta", node.model);
      this.loading = false;

      this.funtion(node.model);

      //   this.routers = this.routers + node.model.text;
    },
    itemClick2(node) {
      this.loading = true;
      this.loading = false;
      this.id_pastas_mover = node.model;
    },
    funtion(model) {
      this.achou = true;
      if (this.routers != "") {
        this.routers = this.routers + "\\" + model.text;
      } else {
        this.lista_pastas.forEach((value, index) => {
          //  console.log(this.routers);
          if (this.achou) {
            this.routers = "";
            // this.routers = value.text;
            this.functionrecursivo(value, model);
          }
        });
      }
      // this.routers = this.routers + "\\" + textt;
    },

    functionrecursivo(sub, model) {
      let textt = "";
      if (this.routers != "") {
        textt = this.routers + "\\" + sub.text;
      } else {
        textt = sub.text;
      }

      if (sub.id != model.id) {
        // this.routers = textt;

        if (sub.children) {
          sub.children.forEach((value, index) => {
            if (this.achou) {
              if (value.id == model.id) {
                this.achou = false;
                textt = textt + "\\" + value.text;
                this.routers = textt;
              } else {
                console.log(value.text);
                // textt = this.routers + "\\" + value.text;
                this.routers = textt;

                this.functionrecursivo(value, model);
              }
            }
          });
        }
      } else {
        this.achou = false;
        this.routers = textt;
      }
      // if (sub.id == model.id) {
      // this.routers = textt;

      // this.routers = textt;
      // console.log(  this.routers);
      // }
    },
    async processUpload(data) {
      let formData;
      for (var i = 0; i < data.length; i++) {
        formData = new FormData();
        let file = data[i];
        formData.append("file[" + i + "]", file);
        formData.append("rota", this.routers);
        formData.append("pastas_id", this.subpastas_id);
        formData.append("ocr", this.check_ocr);

      
        this.lista_arquivos_upload_progress.push({
          nome: file.name,
          progress: 0,
        });
    
        await this.$store.dispatch("pastas/create_documento", formData).finally(()=>{
          this.lista_arquivos_upload_progress[
            this.lista_arquivos_upload_progress.length - 1
          ].progress = 100;
        })
      }
      await new Promise(resolve => setTimeout(resolve, 500));
      this.lista_arquivos_upload_progress = [];
    },

    async confirm(tipo, value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação deleta uma ` + tipo + ` no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.delete(tipo, value);
          }
        },
      });
    },
    async delete(tipo, value) {
      switch (tipo) {
        case "Pasta":
          await this.$store.dispatch("pastas/delete_pastas", value);

          this.fireAlert(this.mensagem_alert);

          break;
        case "Arquivo":
          await this.$store.dispatch("pastas/delete_documento", value);
          this.fireAlert(this.mensagem_alert);

          break;
      }

      // this.$store.dispatch("pastas/listar_pastas");
      // this.$store.dispatch("pastas/listar_pastas_doc", 0);
    },
    showModalMoverFolderFile() {
      this.modal_move_folder_file = true;
    },
    hideModalMoverFolderFile() {
      this.modal_move_folder_file = false;
    },
    mover() {
      let form = {
        id: this.pasta_select.id,
        nome: this.pasta_select.text,
        subpastas_id: this.id_pastas_mover.id,
      };
      this.$store.dispatch("pastas/update_pastas", form);
      this.listar_pastas();
    },
  },
};
</script>

<style scoped>
</style>
