<template>
  <div>
    <div class="d-flex justify-content-between">
      <h1>Diretorios</h1>
      <v-btn elevation="2" color="primary" @click="meusarquivo"
        >Raiz Diretorios</v-btn
      >
    </div>
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-card :elevation="hover ? 24 : 6" class="pt-2">
          <v-jstree
            style="overflow-y: auto; min-height: 100px"
            :data="lista_pastas"
            multiple
            allow-batch
            whole-row
            @item-click="itemClick"
          ></v-jstree> </v-card></template
    ></v-hover>
  </div>
</template>

<script>
import VJstree from "vue-jstree";
export default {
  components: {
    VJstree,
  },
  data() {
    return {
      lista_pastas: this.$store.state.pastas.lista_pastas,
    };
  },
  created() {
    this.listar_pastas();
  },
  computed:{
    new_pasta() {
      return this.$store.state.pastas.new_pasta;
    },
  },
  methods: {
    async listar_pastas() {
      if (!this.lista_pastas[0]) {
        await this.$store.dispatch("pastas/listar_pastas");
        await this.$store
          .dispatch("pastas/listar_pastas_doc", 0)
          .finally(() => {
            this.$store.dispatch("configEmpresa/atualizar", "");
          });
        this.lista_pastas = this.$store.state.pastas.lista_pastas;
      } else {
        this.lista_pastas = this.$store.state.pastas.lista_pastas;
        this.lista_pastas.forEach((eit) => {
          if (eit.id == this.new_pasta.subpastas_id) {
            eit.children.push({
              id: this.new_pasta.id,
              opened: false,
              selected: false,
              sequencia: this.new_pasta.sequencia,
              subpastas_id: this.subpastas_id,
              text: this.new_pasta.nome,
            });
          } else if (eit.children) {
            this.search(eit);
          }
        });
      }
    },
    async meusarquivo() {
      await this.$store.dispatch("pastas/set_pasta", {});
      await this.$store.dispatch("pastas/listar_pastas_doc", 0);
      await this.$store.dispatch("pastas/listar_pastas");
      this.lista_pastas = this.$store.state.pastas.lista_pastas;
    },
    search(dev) {
      dev.children.forEach((itch) => {
        if (itch.id == this.new_pasta.subpastas_id) {
          itch.children.push({
            id: this.new_pasta.id,
            opened: false,
            selected: false,
            sequencia: this.new_pasta.sequencia,
            subpastas_id: this.new_pasta.subpastas_id,
            text: this.new_pasta.nome,
          });
        } else {
          if (itch.children) {
            this.search(itch);
          }
        }
      });
    },
    itemClick(node) {
      this.$emit("clickPasta", node);
    },
  },
};
</script>